import React, { useState, useEffect } from 'react';
import { Status } from '../Status/Status';
import { clsx } from 'clsx';
import unknownHero from './unknown.png';
import Atropos from 'atropos/react';

export type CardProps = {
  score: number;
  level?: number;
  username: string;
  isLeader?: boolean;
  onClick: () => void;
  place?: number;
  image: string;
};

const CARD_WIDTH = window.innerWidth >= 768 ? 160 : '100%';
const CARD_HEIGHT = 240;

export const Card = ({
  score,
  level,
  username,
  isLeader,
  image,
  place,
  onClick,
}: CardProps) => {
  return (
    <Atropos shadow={false} rotateXMax={25} rotateYMax={25}>
      <div
        className={clsx('rounded flex flex-col p-3.5 cursor-pointer', {
          'bg-signal23-mirage-500': isLeader,
          'hover:bg-signal23-saffronMango-300': isLeader,
          'hover:bg-signal23-saffronMango-100': !isLeader,
        })}
        onClick={onClick}
        style={{ width: CARD_WIDTH, height: CARD_HEIGHT }}
      >
        <div>
          <div
            className={clsx('flex-row flex items-center w-full relative h-7', {
              'justify-between': isLeader,
              'justify-center': !isLeader,
            })}
          >
            <span
              className={clsx(
                'font-mono text-sm',
                { 'text-white': isLeader },
                { 'text-gray-300': !isLeader }
              )}
              data-atropos-offset="10"
            >
              {score?.toLocaleString()}
            </span>
            {isLeader && (
              <span
                className="font-mono text-xs bg-green-500 rounded-md text-white p-3 py-2 text-indigo-600"
                data-atropos-offset="5"
              >
                {place}
              </span>
            )}
          </div>

          <div className="mt-4 mb-3 text-center flex justify-center">
            <object
              data={image}
              type="image/png"
              width="100"
              data-atropos-offset="10"
            >
              <img src={unknownHero} width={100} style={{ maxHeight: 120 }} />
            </object>
          </div>
          <div
            className="flex justify-center mh-12 h-12 text-center overflow-hidden"
            data-atropos-offset="10"
          >
            <span
              className={clsx(
                'font-text h-full text-ellipsis overflow-hidden',
                { 'text-white': isLeader },
                { 'text-gray-800 ': !isLeader }
              )}
            >
              {username || 'Unknown'}
            </span>
          </div>
        </div>
      </div>
    </Atropos>
  );
};
