import { useState, useEffect } from 'react';

// Based on https://usehooks.com/useWindowSize/
// Added max width / height
export function useWindowSize(maxWidth?: number, maxHeight?: number) {
  const [windowSize, setWindowSize] = useState<{
    width: number;
    height: number;
  }>({
    width: 1064,
    height: 768,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width:
          maxWidth && window.innerWidth > maxWidth
            ? maxWidth
            : window.innerWidth,
        height:
          maxHeight && window.innerHeight > maxHeight
            ? maxHeight
            : window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
