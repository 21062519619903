import React, { useMemo, useContext, createContext, useEffect } from 'react';
import { FixedSizeGrid } from 'react-window';
import { ReactWindowScroller } from 'react-window-scroller';

import { Card } from '../Card';
import { useWindowSize } from '../../hooks/useWindowSize';
import { AppContext } from '../../contexts/AppContext';

const COLUMN_WIDTH = 200;
const ROW_HEIGHT = 260;

interface CellProps {
  columnIndex: number;
  rowIndex: number;
  style: any;
  data: any;
}

const Cell = ({ columnIndex, rowIndex, style, data }: CellProps) => {
  const {
    state: { columnCount, ...state },
    setSelectedHero,
  } = useContext(AppContext);
  const itemIndex = rowIndex * columnCount + columnIndex;
  const cardData = data[itemIndex];

  const isFiltered = state?.filter;

  return cardData ? (
    <div style={style}>
      <Card
        isLeader={false}
        place={itemIndex + 1}
        {...cardData}
        onClick={() => {
          setSelectedHero(cardData);
        }}
      />
    </div>
  ) : null;
};

export const Grid = ({
  items,
  width = 1920,
  showLeaders,
}: {
  items: any[];
  width?: number;
  showLeaders?: boolean;
}) => {
  const { height } = useWindowSize();
  const { state, setColumnCount, isInitialized } = useContext(AppContext);

  useEffect(() => {
    if (state.isInitialized) {
      setColumnCount(Math.floor(width / COLUMN_WIDTH));
    }
  }, [state.isInitialized, width]);

  return (
    <div className="text-center lg:text-left">
      <ReactWindowScroller isGrid key={state.columnWidth}>
        {({ ref, outerRef, style, onScroll }: any) => (
          <FixedSizeGrid
            columnCount={state.columnCount}
            outerRef={outerRef}
            ref={ref}
            style={{ ...style, overflow: 'unset' }}
            onScroll={onScroll}
            columnWidth={COLUMN_WIDTH}
            itemData={items}
            height={height}
            rowCount={Math.ceil(items?.length / state.columnCount)}
            rowHeight={ROW_HEIGHT}
            width={width}
          >
            {Cell}
          </FixedSizeGrid>
        )}
      </ReactWindowScroller>
    </div>
  );
};
