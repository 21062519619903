import { Grid } from './components/Grid/Grid';

import { initialAppState, AppContext, AppState } from './contexts/AppContext';

import Modal from 'react-modal';
import search from './search.svg';
import { useRef, createContext, useReducer, useEffect } from 'react';
import { useElementSize } from './hooks/useElementSize';
import Loading from 'react-loading';
import { Status } from './components/Status/Status';
import { useGetHeroes, useSearchHeroes, useGetHero } from './queries/heroes';
import unknownHero from './components/Card/unknown.png';

import debounce from './helpers/debounce';
import ReactGA from 'react-ga';

const isLarger = window.innerWidth >= 768;

const INPUT_WIDTH = isLarger ? 370 : '100%';
const MODAL_WIDTH = 360;
const MODAL_HEIGHT = 500;

type Reducer = (state: AppState, action: ActionType) => AppState;

const ActionHandlers = {
  setInitialized: 'setInitialized',
  setLoading: 'setLoading',
  setColumnCount: 'setColumnCount',
  setLeaders: 'setLeaders',
  setHeroes: 'setHeroes',
  appendHeroes: 'appendHeroes',
  setSelectedHero: 'setSelectedHero',
  clearSelectedHero: 'clearSelectedHero',
  setFilter: 'setFilter',
  clearFilter: 'clearFilter',
  setFilteredHeroes: 'setFilteredHeroes',
  appendFilteredHeroes: 'appendFilteredHeroes',
  clearFilteredHeroes: 'clearFilteredHeroes',
};

const MAX_SCORE = 25000;

type ActionType =
  | { type: 'setInitialized' }
  | { type: 'setLoading'; payload: { isLoading: boolean } }
  | { type: 'setColumnCount'; payload: { columnCount: number } }
  | {
      type: 'setLeaders';
      payload: {
        leaders: any;
      };
    }
  | { type: 'setHeroes'; payload: { heroes: any } }
  | {
      type: 'setSelectedHero';
      payload: { hero: string };
    }
  | {
      type: 'clearSelectedHero';
    }
  | { type: 'setFilter'; payload: { filter: string } }
  | { type: 'clearFilter' }
  | { type: 'setFilteredHeroes'; payload: { heroes: any } }
  | { type: 'appendFilteredHeroes'; payload: { heroes: any } };

const actionHandlers = {
  [ActionHandlers.setInitialized]: (state: AppState) => {
    return { ...state, isInitialized: true };
  },
  setLoading: (state: AppState, { isLoading }: { isLoading: boolean }) => {
    return { ...state, isLoading };
  },
  setColumnCount: (
    state: AppState,
    { columnCount }: { columnCount: number }
  ) => {
    return { ...state, columnCount };
  },
  setLeaders: (state: AppState, { leaders }: { leaders: any }) => {
    return { ...state, leaders };
  },
  setHeroes: (state: AppState, { heroes }: { heroes: any }) => {
    return { ...state, isLoading: false, isInitialized: true, heroes };
  },

  setFilteredHeroes: (state: AppState, { heroes }: { heroes: any }) => {
    return {
      ...state,
      isLoading: false,
      isInitialized: true,
      filteredHeroes: heroes,
    };
  },

  appendFilteredHeroes: (state: AppState, { heroes }: { heroes: any }) => {
    return {
      ...state,
      isLoading: false,
      isInitialized: true,
      filteredHeroes: state.filteredHeroes
        ? [...state.filteredHeroes, ...heroes]
        : heroes,
    };
  },

  appendHeroes: (state: AppState, { heroes }: { heroes: any }) => {
    return {
      ...state,
      isLoading: false,
      isInitialized: true,
      heroes: [...state.heroes, ...heroes],
    };
  },
  setSelectedHero: (state: AppState, { hero }: { hero: any }) => {
    ReactGA.event({
      category: 'HeroWall',
      action: 'Select Hero',
      label: `${hero.username} (${hero.id})`,
    });

    return { ...state, selectedHero: hero };
  },
  clearSelectedHero: (state: AppState) => {
    return { ...state, selectedHero: undefined };
  },

  setFilter: (state: AppState, { filter }: { filter: string }) => {
    ReactGA.event({
      category: 'HeroWall',
      action: 'Filter Heroes',
      label: filter,
    });

    return { ...state, filter };
  },

  clearFilter: (state: AppState) => {
    return { ...state, filter: undefined };
  },
};

function reducer(state: AppState, action: ActionType) {
  if (actionHandlers.hasOwnProperty(action.type)) {
    return actionHandlers[action.type](state, (action as any).payload);
  } else return state;
}

function HeroWall() {
  const [headerEl, { width, height }] = useElementSize();
  const [state, dispatch]: any = useReducer<any>(reducer, initialAppState);
  const getHeroes = useGetHeroes();
  const getHero = useGetHero();
  const searchHeroes = useSearchHeroes();

  const setInitialized = () => {
    dispatch({ type: 'setInitialized' });
  };

  const mapHero = (baseHero: any, isFromFilter?: boolean) => {
    try {
      if (isFromFilter) {
        return {
          id: baseHero.id,
          username: baseHero?.displayName,
          description: baseHero?.bio,
          image: `${process.env.REACT_APP_CDN_HERO_IMAGE_PATH}/twilio-hero-${baseHero?.id}.png`,
        };
      }

      return {
        id: baseHero.id,
        username: baseHero?.displayName,
        description: baseHero?.bio,
        image: `${process.env.REACT_APP_CDN_HERO_IMAGE_PATH}/twilio-hero-${baseHero.id}.png`,
      };
    } catch (ex) {
      console.error(ex);
      return undefined;
    }
  };

  const mapHeroes = (items: any, isFromFilter?: boolean) => {
    return items
      .filter((item: any) => {
        return isFromFilter ? item : item;
      })
      .map((item: any) => mapHero(item, isFromFilter));
  };

  const setHeroes = (heroes: any) => {
    dispatch({ type: ActionHandlers.setHeroes, payload: { heroes } });
  };

  const setFilteredHeroes = (heroes: any) => {
    dispatch({ type: ActionHandlers.setFilteredHeroes, payload: { heroes } });
  };

  const appendFilteredHeroes = (heroes: any) => {
    dispatch({
      type: ActionHandlers.appendFilteredHeroes,
      payload: { heroes },
    });
  };

  const appendHeroes = (heroes: any) => {
    dispatch({ type: ActionHandlers.appendHeroes, payload: { heroes } });
  };

  const setLeaders = (leaders: any) => {
    dispatch({ type: ActionHandlers.setHeroes, payload: { leaders } });
  };

  const setColumnCount = (columnCount: number) => {
    dispatch({ type: ActionHandlers.setColumnCount, payload: { columnCount } });
  };

  const setSelectedHero = (hero: any) => {
    dispatch({ type: ActionHandlers.setSelectedHero, payload: { hero } });
  };

  const clearSelectedHero = () => {
    dispatch({ type: ActionHandlers.clearSelectedHero });
  };

  const setFilter = (filter: string) => {
    if (filter === '') {
      dispatch({ type: ActionHandlers.clearFilter });
      return;
    }

    dispatch({ type: ActionHandlers.setFilter, payload: { filter } });
  };

  const safeSetFilter = debounce(setFilter, 500);

  const clearFilter = () => {
    dispatch({ type: ActionHandlers.clearFilter });
  };

  const loadFilteredHeroes = async (filter: string, nextToken?: string) => {
    const heroes = await searchHeroes(filter, nextToken);
    const mappedHeroes = mapHeroes(heroes?.data?.searchHeroBios?.items, true);
    const futureNextToken = heroes?.data?.searchHeroBios?.nextToken;

    if (!nextToken) {
      setFilteredHeroes(mappedHeroes);
    } else {
      appendFilteredHeroes(mappedHeroes);
    }

    if (futureNextToken) {
      loadFilteredHeroes(filter, futureNextToken);
    }
  };

  const loadHero = async (heroId: string) => {
    const hero = await getHero(heroId);
    const mappedHero = mapHero(hero?.data?.getHeroBio);

    if (mappedHero) {
      setSelectedHero(mappedHero);
    }
  };

  const loadHeroes = async (nextToken?: string) => {
    const heroes = await getHeroes(nextToken);
    const mappedHeroes = mapHeroes(heroes?.data?.listHeroBios?.items);
    const futureNextToken = heroes.data.listHeroBios.nextToken;

    // We want to initialize the hero collection when next token isn't present
    // and append the heroes when next token is present.
    // e.g. nextToken implies that we are loading as paged data after initial set is loaded
    if (!nextToken) {
      setHeroes(mappedHeroes);
    } else {
      appendHeroes(mappedHeroes);
    }

    // if our new dataset has a nextToken, load the next page into our collection
    if (futureNextToken) {
      loadHeroes(futureNextToken);
    }
  };

  useEffect(() => {
    let heroId = new URL(window.location.href).searchParams.get('heroId');
    if (heroId) {
      loadHero(heroId);
    }

    //initialize react-ga
    ReactGA.initialize('UA-144358732-1', {
      gaOptions: {
        storage: 'none',
        storeGac: false,
      },
    });

    ReactGA.pageview('/hero-wall');

    loadHeroes();
  }, []);

  useEffect(() => {
    if (state.filter) {
      loadFilteredHeroes(state.filter);
    }
  }, [state.filter]);

  return (
    <AppContext.Provider
      value={{
        state,
        setInitialized,
        setHeroes,
        setLeaders,
        setColumnCount,
        setSelectedHero,
        clearSelectedHero,
        setFilter,
        clearFilter,
      }}
    >
      {state.isInitialized ? (
        <div className="p-12 box-border">
          <div
            className="flex md:flex-row flex-col justify-between mb-[60px]"
            ref={headerEl}
          >
            <h2 className="text-indigo-500 font-text font-bold text-3xl mb-4 md:mb-0 ">
              Hero Wall
            </h2>
            
            { <div className="relative">
              <img
                src={search}
                height="16"
                width="16"
                alt=""
                className="absolute top-4 left-3"
              />
              <input
                type="text"
                className="border border-gray-200 rounded p-3 px-8 w-full md:w-auto "
                placeholder="Search player names and bios"
                style={{ width: INPUT_WIDTH }}
                onChange={(e) => {
                  safeSetFilter(e.target.value);
                }}
              />
            </div>}
          </div>
          {state.filter &&
            (!state.filteredHeroes || state.filteredHeroes.length === 0) && (
              <h2 className="text-2xl text-signal23-mirage-400">No Heroes found</h2>
            )}
          <Grid
            items={state.filter ? state.filteredHeroes : state.heroes}
            width={width}
          />
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen">
          <Loading type="spin" color="#4A5A86" height={100} width={100} />
        </div>
      )}

      <Modal
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={() => {
          clearSelectedHero();
        }}
        isOpen={!!state.selectedHero}
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 backdrop-blur"
        style={{
          content: {
            padding: 0,
            width: MODAL_WIDTH,
            height: MODAL_HEIGHT,
            overflow: 'hidden',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: 0,
            borderWidth: "1px",
            borderColor:  '#141C2C',
          },
        }}
      >
        <div
          className="flex flex-row justify-between border-b border-signal23-mirage-500"
          style={{ height: 60 }}
        >
          <span
            className="flex items-center justify-center text-mirage23-500 font-text bg-signal23-saffronMango-500"
            style={{ width: 104 }}
          >
          </span>
          <span
            className="flex items-center border-signal23-mirage-500 border-x justify-center bg-signal23BlueDiagonalLineBkgr"
            style={{ width: 164 }}
          > 
          </span>
          <span className="flex items-center justify-center border-signal23-mirage-500 border-r bg-signal23-redRibbon-500" style={{ width: 20 }}></span>
          <span
            className="flex text-2xl items-center justify-center text-indigo-300"
            style={{ width: 72 }}
          >
            <button
              className="w-full h-full"
              onClick={() => {
                clearSelectedHero();
              }}
            >
              &times;
            </button>
          </span>
        </div>
        <div
          className="w-full text-center flex items-center justify-center bg-signal23-roseWhite-500"
          style={{ height: 240 }}
        >
          {state.selectedHero?.image && (
            <object
              data={state.selectedHero?.image}
              type="image/png"
              width="180"
              height="180"
            >
              <img src={unknownHero} width="180" height="180" />
            </object>
          )}
        </div>
        <div
          className="border-signal23-mirage-500 border-y flex justify-center items-center bg-signal23-algaeGreen-500"
          style={{ height: 70 }}
        >
          <h3 className="text-signal23-mirage-500 font-mono text-2xl">
            {state.selectedHero?.username}
          </h3>
        </div>
        <p className="p-5 font-mono text-sm bg-white">
          {state.selectedHero?.description}
        </p>
        
      </Modal>
    </AppContext.Provider>
  );
}

export default HeroWall;
