import { createContext } from 'react';
export interface AppState {
  isInitialized: boolean;
  isLoading: boolean;
  isLoadingMore?: boolean;
  columnCount: number;
  heroes: any[];
  leaders?: any[];
  selectedHero?: any;
  filter?: string;
  filteredHeroes?: any[];
}

export const initialAppState: AppState = {
  isInitialized: false,
  isLoading: true,
  isLoadingMore: undefined,
  columnCount: 0,
  heroes: [],
  leaders: [],
  selectedHero: undefined,
  filter: undefined,
};

export const AppContext = createContext<any>(initialAppState);
