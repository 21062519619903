import { gql } from "graphql-tag"
import { useQuery, createRequest, useClient } from "urql"

export const HeroesQuery = gql`
  query GetHeroBios($nextToken: String, $limit: Int) {
    listHeroBios(limit: $limit, nextToken: $nextToken) {
      nextToken
      items {
        id
        attendeeId
        bio
        displayName
        slack
        createdAt
      }
    }
  }
`

export const SearchQuery = gql`
  query Search($phrase: String, $nextToken: String, $limit: Int) {
    searchHeroBios(
      filter: {
        or: [
          { displayName: { matchPhrasePrefix: $phrase } }
          { bio: { matchPhrasePrefix: $phrase } }
          { slack: { matchPhrasePrefix: $phrase } }
        ]
      }
      limit: $limit
      nextToken: $nextToken
    ) {
      nextToken
      items {
        id
        bio
        displayName
        slack
      }
    }
  }
`

export const HeroQuery = gql`
  query getHero($id: ID!) {
    getHeroBio(id: $id) {
      attendeeId
      slack
      displayName
      bio
    }
  }
`

export const useGetHeroes = () => {
  const client = useClient()

  const executeRequest = async (nextToken?: string) => {
    const result = await client
      .query(HeroesQuery, { type: "BIO", limit: 50, nextToken })
      .toPromise()

    return result
  }

  return executeRequest
}

export const useSearchHeroes = () => {
  const client = useClient()

  const executeRequest = async (phrase: string, nextToken?: string) => {
    if (!phrase) {
      return
    }

    const result = await client
      .query(SearchQuery, { limit: 10, nextToken, phrase })
      .toPromise()

    return result
  }

  return executeRequest
}

export const useGetHero = () => {
  const client = useClient()

  const executeRequest = async (id: string) => {
    if (!id) {
      return
    }

    const result = await client.query(HeroQuery, { id }).toPromise()

    return result
  }

  return executeRequest
}
