import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createClient, Provider as UrqlProvider } from 'urql';
import 'atropos/atropos.css';

const client = createClient({
  url: process.env.REACT_APP_GRAPHQL_URL || '',
  fetchOptions: () => {
    return {
      headers: { 'X-Api-Key': process.env.REACT_APP_API_KEY || '' },
    };
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <UrqlProvider value={client}>
    <App />
  </UrqlProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
